import axios from "axios"
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['name', 'id']

  connect() {
    document.addEventListener("pick", (e) => this.pick(e))
  }

  pick(e) {
    axios.get(`/api/elements/${e.detail.id}`).then((response) => {
      this.idTarget.value = response.data.id
      this.nameTarget.innerHTML = response.data.name
    })
  }
 }

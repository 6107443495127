
import { Controller } from "@hotwired/stimulus"
export default class extends Controller {
  static targets = ["chart"]
  static values = {
    data: Array,
    categories: Array,
    stacking: {
      type: String,
      default: "percent"
    }
  }

  connect() {
    this.element[this.identifier] = this
    this.buildChart()
  }

  buildChart() {

    Highcharts.chart(this.chartTarget, {
      chart: {
        type: 'column',
        width: null,
        backgroundColor: 'transparent',
        height: 250
      },
      title: {
        text: ""
      }, 
      credits: {
        enabled: false
      },
      legend: {
        enabled: false
      },
      yAxis: {
        title: false
      },
      xAxis: {
        categories: this.categoriesValue,
      },
      tooltip: {
        shared: true
      },
      plotOptions: {
        column: {
          stacking: this.stackingValue,
          dataLabels: {
            enabled: false
          }
        },
        series: {
          point: {
            events: {
              click: function() {
                if(this.options.url && this.options.url.length > 5) {
                  window.location.href = this.options.url
                }
              }
            }
          }
        },
      },
      series: this.dataValue
    });
  }
 }

import { Controller } from "@hotwired/stimulus"
import { Turbo } from "@hotwired/turbo-rails"

export default class extends Controller {
  static targets = ["box","all","count",'frame','field','source','action']

  connect() {
    this.checked = []
    this.sourceTarget.value = window.location
    this.frameTarget.addEventListener("turbo:frame-render", (e) => {
      this.sourceTarget.value = e.detail.fetchResponse.response.url
      this.updateBoxes()
    })
  }

  toggle(e) {
    if(!e.target.checked) {
      this.allTarget.checked = false
      this.checked = this.checked.filter((id) => id !== e.target.value)
    } else {
      this.checked.push(e.target.value)
    }
    this.updateCount()
  }

  clear() {
    this.checked = []
    this.allTarget.checked = false
    this.boxTargets.forEach(box => {
      box.checked = false
    })
  }

  updateBoxes() {
    this.boxTargets.forEach(box => {
      box.checked = this.checked.includes(box.value.toString())
    })
    this.updateCount()
  }

  updateCount() {
    this.countTarget.innerHTML = this.checked.length
    this.fieldTarget.value = this.checked.join(",")
    if(this.checked.length > 0) {
      this.actionTarget.classList.remove("hidden")
    } else {
      this.actionTarget.classList.add("hidden")
    }
  }

  toggleAll(e) {
    this.checked = []
    this.boxTargets.forEach(box => {
      box.checked = e.target.checked
      if(e.target.checked) {
        this.checked.push(box.value)
      }
    })
    this.updateCount()
  }

  connne

  
}
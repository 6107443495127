
import { Controller } from "@hotwired/stimulus"
import { Turbo } from "@hotwired/turbo-rails"
export default class extends Controller {
  static targets = ["chart"]
  static values = {
    data: Array,
    nodes: Array,
    dates: Array
  }

  connect() {
    this.element[this.identifier] = this
    this.buildChart()
  }

  buildChart() {

    const dates = this.datesValue

    Highcharts.chart(this.chartTarget, {
      chart: {
        type: 'sankey',
        width: null,
        backgroundColor: 'transparent',
        height: 360
      },
      title: {
        text: ""
      }, 
      credits: {
        enabled: false
      },
      legend: {
        enabled: true
      },
      tooltip: {
        shared: true,
        formatter: function () {
          const from = this.point.fromNode.name
          const to = this.point.toNode.name

          return from + ' -> ' + to + ': ' + this.point.weight;
        }
      },
      plotOptions: {
        series: {
          point: {
            events: {
              click: function() {
                const id = this.id
                const element = document.getElementById(id)
                if(element) {
                  console.log(element)
                  element.click()
                }
              }
            }
          }
        },
      },
      series: [
        {
          data: this.dataValue,
          nodes: this.nodesValue,
          type: "sankey",
          name: "Discrepancies",
          minLinkWidth: 5,
          nodePadding: 20
        }
      ]
    });
  }
 }


import { Controller } from "@hotwired/stimulus"
export default class extends Controller {
  static targets = ["chart"]
  static values = {
    data: Array
  }

  connect() {
    this.element[this.identifier] = this
    this.buildChart()
  }

  buildChart() {

    Highcharts.chart(this.chartTarget, {
      chart: {
        type: 'pie',
        width: null,
        backgroundColor: 'transparent'
      },
      title: {
        text: ""
      }, 
      credits: {
        enabled: false
      },
      plotOptions: {
        series: {
          point: {
            events: {
              click: function() {
                if(this.options.url && this.options.url.length > 5) {
                  window.location.href = this.options.url
                }
              }
            }
          }
        },
        pie: {
          shadow: false,
          dataLabels: {
            enabled: true,
            format: '<b>{point.name}</b>:<br/>{point.y}'
          }
        }
      },
      tooltip: {
        formatter() {
          return `<b>${this.series.name} - ${this.point.name}</b><br/>${this.y} &ndash; ${this.percentage.toFixed(2)}%`
        }
      },
      series: this.dataValue
    });
  }
 }

import { Controller } from "@hotwired/stimulus"
import * as FilePond from 'filepond';
import TomSelect from 'tom-select'

export default class extends Controller {
  static targets = ["newInput", "existingDropdown","modeInput"]

  replace(e) {
    e.preventDefault()
    this.modeInputTarget.value = "replace"
    this.element.classList.remove("has-file")
    this.element.classList.remove("new-version")
    this.selectize.clear()

  }
  upload(e) {
    e.preventDefault()
    this.modeInputTarget.value = "new_version"
    this.element.classList.add("new-version")
    this.selectize.clear()
  }

  connect() {

    if(this.existingDropdownTarget) {
      const select = this.existingDropdownTarget.querySelector("select")
      const defaultOptions = {
        placeholder: "Select...",
        plugins: ['dropdown_input','clear_button'],
        hidePlaceholder: true,
        maxItems: 1,
        create: false,
        onChange: (value) => {
          if(value) {
            this.element.classList.add("selected-existing-file")
          } else {
            this.element.classList.remove("selected-existing-file")
          }
        }
      }
      this.selectize = new TomSelect(select, defaultOptions)
    }

    FilePond.create(this.newInputTarget, {
      server: {
        process: "/api/file_uploads",
        load: "/api/file_uploads/show"
      },
      onremovefile: (error, file) => {
        this.element.classList.remove("uploaded-new-file")
      },
      onprocessfiles: () => {
        this.element.classList.add("uploaded-new-file")
      }
    } );
  }

}

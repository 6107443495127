
import { Controller } from "@hotwired/stimulus"
import dayjs from 'dayjs'

export default class extends Controller {
  static targets = ["chart"]
  static values = {
    data: Array,
  }

  connect() {
    this.element[this.identifier] = this
    this.buildChart()
  }

  buildChart() {

    Highcharts.chart(this.chartTarget, {
      chart: {
        type: 'area',
        width: null,
        backgroundColor: 'transparent',
        height: 400
      },
      title: {
        text: ""
      }, 
      credits: {
        enabled: false
      },
      legend: {
        enabled: true
      },
      yAxis: {
        title: false
      },
      xAxis: {
        type: 'datetime', 
      },
      tooltip: {
        shared: true,
        useHTML: true,
        formatter() {
          let html 
          html = `<div class="tooltip-title">${dayjs(this.x).format('MMM YYYY')}</div><table class="tooltip-table">`
   
          if(this.points) {

            this.points.forEach(o => {
              html += `<tr>
                <td class="tooltip-color">
                  <div style="background-color: ${o.series.color}"></div>
                </td>
                <td class="tooltip-series">${o.series.name}</td>
                <td class="tooltip-value" style="padding-left: 20px">${o.y}</td>
                <td class="tooltip-value" style="padding-left: 20px">${o.percentage.toFixed(2)}%</td>
              </tr>`
            })

          } else {
              html += `<tr>
                <td class="tooltip-series">
                  ${this.series.name}
                </td>
                <td class="tooltip-value">${o.y}</td>
              </tr>`
          }

          html += "</table>"

          return html
        } 
      },
      plotOptions: {
        area: {
          stacking: 'percent',
          dataLabels: {
            enabled: false
          }
        },
      },
      series: this.dataValue
    });
  }
 }

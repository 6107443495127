
import { Controller } from "@hotwired/stimulus"
export default class extends Controller {
  static targets = ["chart"]
  static values = {
    data: Array
  }

  connect() {
    this.element[this.identifier] = this
    this.buildChart()
  }

  buildChart() {

    Highcharts.chart(this.chartTarget, {
      chart: {
        type: 'column',
        width: null,
        backgroundColor: 'transparent'
      },
      title: {
        text: ""
      }, 
      yAxis: [{
        title: { text: false }
      }, {
        title: { text: false },
        opposite: true
      }],
      credits: {
        enabled: false
      },
      xAxis: {
        type: 'datetime'
      },
      legend: {
        enabled: false
      },
      tooltip: {
        formatter: function() {
          
          console.log(this)

          var out = '<b>w/c ' + Highcharts.dateFormat('%e %b %Y', this.x) + '</b><br/>'
          out += '<span style="margin: 5px 0">' + this.point.name + '</span><br/>'
          out += this.point.stackTotal;
          return out

        }
      },
      plotOptions: {
        column: {
          stacking: 'normal',
          dataLabels: {
            enabled: false
          }
        },
        series: {
          point: {
            events: {
              click: function() {
                if(this.options.url && this.options.url.length > 5) {
                  window.location.href = this.options.url
                }
              }
            }
          }
        },
      },
      series: this.dataValue
    });
  }
 }

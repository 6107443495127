import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  copy(event) {
    event.preventDefault()
    navigator.clipboard.writeText(this.element.textContent)
    this.element.classList.add("clicked")
    setTimeout(() => this.element.classList.remove("clicked"), 1000)
    setTimeout(() => this.element.classList.add("ticked"), 250)
  }


}


import { Controller } from "@hotwired/stimulus"
export default class extends Controller {
  static targets = ["chart"]
  static values = {
    data: Array
  }

  connect() {
    this.element[this.identifier] = this
    this.buildChart()
  }

  buildChart() {

    Highcharts.chart(this.chartTarget, {
      chart: {
        type: 'line',
        width: null,
        backgroundColor: 'transparent'
      },
      title: {
        text: ""
      }, 
      yAxis: [{
        title: { text: false }
      }, {
        title: { text: false },
        opposite: true
      }],
      credits: {
        enabled: false
      },
      xAxis: {
        type: 'datetime'
      },
      tooltip: {
        shared: true,
      },
      plotOptions: {
        series: {
          point: {
            events: {
              click: function() {
                if(this.options.url && this.options.url.length > 5) {
                  window.location.href = this.options.url
                }
              }
            }
          }
        },
      },
      series: this.dataValue
    });
  }
 }

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = ["code"]

  priorityHighlight(e) {
    this.codeTargets.forEach(target => {
      if(target.dataset.priority == e.target.dataset.priority) {
        target.classList.add("code-highlighted")
      } else {
        target.classList.add("code-faded")
      }
    })
  }

  highlight(e) {

    this.codeTargets.forEach(target => {
      if(target.dataset.code == e.target.dataset.code) {
        target.classList.add("code-highlighted")
      } else {
        target.classList.add("code-faded")
      }
    })
  }

  unhighlight(e) {
    this.codeTargets.forEach(target => {
      target.classList.remove("code-highlighted")
      target.classList.remove("code-faded")
    })
  }

}
